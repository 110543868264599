<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="items"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'Contact',
          disabled: true,
          exact: true,
          to: { name: 'contact' },
        },
      ],
    };
  },
};
</script>
